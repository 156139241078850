import React from "react"
import * as styles from "./styles.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Container from "../Container/Container"

const Hero = () => {
  const hero = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Container>
      <section className={styles.heroSection}>
        <div className={styles.heroText}>
          <h3 className={styles.heroHeadingSub}>We're Awesome</h3>
          <h1 className={styles.heroHeadingMain}>
            <span>Business Group That Help You To Go Ahead</span>
          </h1>
        </div>
        <div className={styles.heroImage}>
          <Img
            className={styles.heroImageRef}
            fluid={hero.placeholderImage.childImageSharp.fluid}
          />
        </div>
      </section>
      <section className={styles.sectionText}>
        <h1 className={styles.sectionTextMain}>
          We provide solutions in various sectors like Logisitics, Constructions
          and traders
        </h1>
        <h3 className={styles.sectionTextSub}>
          we work with our clients to provide best solution to their problems.
        </h3>
      </section>
    </Container>
  )
}

export default Hero
