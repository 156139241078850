// extracted by mini-css-extract-plugin
export var aboutButton = "styles-module--aboutButton--0a82c";
export var aboutHeader = "styles-module--aboutHeader--32472";
export var aboutHeading = "styles-module--aboutHeading--ff00d";
export var aboutHeadingMain = "styles-module--aboutHeadingMain--3867f";
export var aboutHeadingSub = "styles-module--aboutHeadingSub--ae496";
export var aboutImage = "styles-module--aboutImage--30add";
export var aboutImageRef = "styles-module--aboutImageRef--4e813";
export var aboutInfo = "styles-module--aboutInfo--01d5d";
export var aboutSection = "styles-module--aboutSection--091ab";
export var achCount = "styles-module--achCount--7fc32";
export var achCountRef = "styles-module--achCountRef--5650d";
export var achHeader = "styles-module--achHeader--9cc34";
export var achHeadingMain = "styles-module--achHeadingMain--80e51";
export var achHeadingSub = "styles-module--achHeadingSub--f1a5b";
export var achNumber = "styles-module--achNumber--636e1";
export var achText = "styles-module--achText--1afa3";
export var achievementSection = "styles-module--achievementSection--b18f0";