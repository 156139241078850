// extracted by mini-css-extract-plugin
export var deskLine = "styles-module--deskLine--9a23d";
export var mobLine = "styles-module--mobLine--631e9";
export var serviceHeader = "styles-module--serviceHeader--9a92f";
export var serviceHeading = "styles-module--serviceHeading--ca1fc";
export var serviceHeadingMain = "styles-module--serviceHeadingMain--c6805";
export var serviceHeadingSub = "styles-module--serviceHeadingSub--20fd7";
export var serviceInfo = "styles-module--serviceInfo--43b9f";
export var serviceList = "styles-module--serviceList--c75b4";
export var serviceListHeading = "styles-module--serviceListHeading--f820c";
export var serviceListHeadingSub = "styles-module--serviceListHeadingSub--9fb5a";
export var serviceListImg = "styles-module--serviceListImg--c83eb";
export var serviceSection = "styles-module--serviceSection--d2237";
export var services = "styles-module--services--a952a";