import React, { useEffect } from "react"
import * as styles from "./styles.module.scss"
import Container from "../Container/Container"
import { Link } from "gatsby"
import "aos/dist/aos.css"

//SVG
import Construction from "../../images/Construction.svg"
import Logistics from "../../images/Logistics.svg"
import Traders from "../../images/Traders.svg"
import DeskLine from "../../images/DeskLine.svg"
import PhoneLine from "../../images/MobLine.svg"

const Services = () => {
  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })

  return (
    <Container>
      <section
        className={styles.serviceSection}
        data-aos="zoom-out"
        data-aos-duration="2000"
      >
        <div className={styles.serviceHeader}>
          <div className={styles.serviceHeading}>
            <h3 className={styles.serviceHeadingSub}>Our Capabalities</h3>
            <h1 className={styles.serviceHeadingMain}>
              We are Specialized in the Following <span>Services</span>
            </h1>
          </div>
          <p className={styles.serviceInfo}>
            UK group is a business group founded by UK Yoosuf in 1993 and is one
            of the leading business group in India with over 25+ years of
            experience in various fields.
          </p>
        </div>
        <div className={styles.services}>
          <div
            className={styles.serviceList}
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <img
              src={Construction}
              alt="Contruction"
              className={styles.serviceListImg}
            ></img>
            <h1 className={styles.serviceListHeading}>Contruction</h1>
            <h3 className={styles.serviceListHeadingSub}>
              Expertise in Commercial and Residential projects
            </h3>
            <Link to="/services/construction">Learn More &rarr;</Link>
          </div>
          <img
            src={DeskLine}
            className={styles.deskLine}
            alt="line"
            data-aos="zoom-in"
            data-aos-duration="2000"
          ></img>
          <img
            src={PhoneLine}
            className={styles.mobLine}
            alt="line"
            data-aos="zoom-in"
            data-aos-duration="2000"
          ></img>
          <div
            className={styles.serviceList}
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <img
              src={Logistics}
              alt="Logistics"
              className={styles.serviceListImg}
            ></img>
            <h1 className={styles.serviceListHeading}>Logistics</h1>
            <h3 className={styles.serviceListHeadingSub}>
              Best in-class Logistics system with GPS tracking
            </h3>
            <Link to="/services/logistics">Learn More &rarr;</Link>
          </div>
          <img
            src={DeskLine}
            className={styles.deskLine}
            alt="line"
            data-aos="zoom-in"
            data-aos-duration="2000"
          ></img>
          <img
            src={PhoneLine}
            className={styles.mobLine}
            alt="line"
            data-aos="zoom-in"
            data-aos-duration="2000"
          ></img>

          <div
            className={styles.serviceList}
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <img
              src={Traders}
              alt="Traders"
              className={styles.serviceListImg}
            ></img>
            <h1 className={styles.serviceListHeading}>Traders</h1>
            <h3 className={styles.serviceListHeadingSub}>
              Granite, Marbles, Tiles and Sanitarywares
            </h3>
            <Link to="/services/traders">Learn More &rarr;</Link>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default Services
