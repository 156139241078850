import React, { useEffect } from "react"
import * as styles from "./styles.module.scss"
import Container from "../Container/Container"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "aos/dist/aos.css"

const About = () => {
  const about = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "About.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })

  return (
    <Container>
      <section className={styles.aboutSection}>
        <div
          className={styles.aboutHeader}
          data-aos="fade-left"
          data-aos-duration="2500"
        >
          <div className={styles.aboutHeading}>
            <h3 className={styles.aboutHeadingSub}>About Us</h3>
            <h1 className={styles.aboutHeadingMain}>
              Here We Work For your Business Grow Up & Give You Valuable{" "}
              <span>Business Tips</span>
            </h1>
          </div>
          <p className={styles.aboutInfo}>
            Started in 1993, UK Traders is one of the largest Granite, Marble,
            Tiles dealer in North Kerala. We ship all the goods from factories
            in different states to our outlet using our Logistics making it the
            only company with such facility in Kerala. We have been doing
            business with people for the past 25+ years and providing various
            services in Construction, Logistics, and Traders.
          </p>
          <Link to="/about" className={styles.aboutButton}>
            Read more &rarr;
          </Link>
        </div>
        <div
          className={styles.aboutImage}
          data-aos="fade-right"
          data-aos-duration="2500"
        >
          <Img
            fluid={about.placeholderImage.childImageSharp.fluid}
            className={styles.aboutImageRef}
          />
        </div>
      </section>
      <section
        className={styles.achievementSection}
        data-aos="fade-up"
        data-aos-duration="2500"
      >
        <div className={styles.achHeader}>
          <h3 className={styles.achHeadingSub}>Our Achivement</h3>
          <h1 className={styles.achHeadingMain}>
            We Got Those Success By Our <span>Work</span>
          </h1>
        </div>
        <div className={styles.achCount}>
          <div className={styles.achCountRef}>
            <h1 className={styles.achNumber}>25+</h1>
            <h3 className={styles.achText}>Years of Experience</h3>
          </div>
          <div className={styles.achCountRef}>
            <h1 className={styles.achNumber}>100k+</h1>
            <h3 className={styles.achText}>Satisfied Customers</h3>
          </div>
          <div className={styles.achCountRef}>
            <h1 className={styles.achNumber}>150+</h1>
            <h3 className={styles.achText}>Experience Worker</h3>
          </div>
          <div className={styles.achCountRef}>
            <h1 className={styles.achNumber}>15</h1>
            <h3 className={styles.achText}>Award Winner</h3>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default About
